import React, { ReactElement, useContext, useEffect, useState } from "react";
import { ResponsiveLine, Serie } from "@nivo/line";
import { GameInsightAlignmentTrendResponse } from "../api/Requests";
import { UserContext } from "../contexts/UserContext";
import { HttpClient } from "../api/HttpClient";
import { RoomParams } from "../types/RouterTypes";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";

export default function AlignmentTrendGraph(): ReactElement {
    const { authToken } = useContext(UserContext);
    const { id: roomUrlId } = useParams<RoomParams>();
    const theme = useTheme();
    //textColor that matches the theme when it shifts between dark and light
    const textColor = theme.palette.text.primary;
    const [data, setData] = useState<Serie[]>([]);

    const stubData: Serie[] = [
        {
            id: "alignment",
            data: [
                { x: "2025-01-10T00:00:00.000Z", y: 50 },
                { x: "2025-01-11T00:00:00.000Z", y: 50 },
                { x: "2025-01-12T00:00:00.000Z", y: 50 },
                { x: "2025-01-13T00:00:00.000Z", y: 50 },
                { x: "2025-01-14T00:00:00.000Z", y: 50 },
            ],
        },
    ];

    const aglimentScoreToText = (alignmentScore: number): string => {
        if (alignmentScore < 0) {
            return "Unknown";
        } else if (alignmentScore <= 10) {
            return "Very low";
        } else if (alignmentScore <= 30) {
            return "Low";
        } else if (alignmentScore <= 58) {
            return "Medium";
        } else if (alignmentScore <= 70) {
            return "High";
        } else if (alignmentScore <= 99) {
            return "Very high";
        } else if (alignmentScore === 100) {
            return "Perfect";
        } else {
            return "Unknown";
        }
    };

    // Function to determine the color based on the score range
    const getColor = (value: number): string => {
        if (value <= 10) return "red"; // Very low, Low
        if (value <= 58) return "grey"; // Medium
        return "green"; // High and above
    };

    // Define Y-axis values for horizontal lines
    const yAxisIntervalValues = [0, 10, 30, 58, 70, 99, 100];

    const CustomLayerWithScoreIntervalExplanation = ({ yScale }: { yScale: any }) => {
        return (
            <>
                {yAxisIntervalValues.map((value, index) => {
                    // Calculate the midpoint between two consecutive lines
                    const nextValue = yAxisIntervalValues[index + 1];
                    if (nextValue !== undefined) {
                        const midPoint = (yScale(value) + yScale(nextValue)) / 2;

                        return (
                            <g key={value}>
                                {/* Horizontal Line */}
                                <line
                                    x1={0}
                                    x2="100%"
                                    y1={yScale(value)}
                                    y2={yScale(value)}
                                    stroke={getColor(value)} // Color based on range
                                    strokeWidth={1}
                                    strokeDasharray="4 4"
                                />

                                {/* Label in the middle between the lines */}
                                <text
                                    x="92%" // Position slightly outside the right side of the chart
                                    y={midPoint} // Set the vertical position to the midpoint between the lines
                                    textAnchor="end" // Align text to the left
                                    fill={getColor(value)} // Text color matches the line
                                    fontSize="12px" // Font size
                                    dy="0.35em" // Vertically adjust the text to be centered
                                >
                                    {aglimentScoreToText(nextValue)}
                                </text>
                            </g>
                        );
                    }
                    return null;
                })}
            </>
        );
    };

    useEffect(() => {
        setData(stubData);

        const fetchData = async () => {
            const httpClient = new HttpClient(authToken);
            const response = (await httpClient.get(`/rooms/${roomUrlId}/alignment-trend`)) as GameInsightAlignmentTrendResponse;

            const formattedData: Serie[] = [
                {
                    id: "alignment",
                    data: response.aglinmentPoints.map((point) => ({
                        x: point.x, // ISO date string
                        y: point.y, // Average alignment score
                    })),
                },
            ];

            setData(formattedData);
        };
        if (authToken) {
            fetchData();
        }
    }, [authToken]);

    return (
        <div style={{ height: "400px" }}>
            <ResponsiveLine
                data={data.filter((d) => d.id === "alignment")}
                margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                xScale={{ type: "time", format: "%Y-%m-%dT%H:%M:%S.%LZ", precision: "day" }}
                xFormat="time:%Y-%m-%d"
                yScale={{ type: "linear", min: 0, max: 100, stacked: true, reverse: false }}
                axisLeft={{
                    legend: "Alignment Score",
                    legendOffset: -40,
                    legendPosition: "middle",
                }}
                axisBottom={{
                    format: "%b %d",
                    tickValues: "every day",
                    legend: "Session Date",
                    legendOffset: 40,
                    legendPosition: "middle",
                }}
                curve="monotoneX"
                pointSize={10}
                pointBorderWidth={2}
                pointLabelYOffset={-12}
                pointColor={{ from: "color", modifiers: [] }} 
                colors={{ scheme: "dark2" }} // Line color from a color scheme
                lineWidth={3} 
                useMesh={true}
                motionConfig="stiff"
                gridXValues={[]} // Disables grid lines for the x-axis
                gridYValues={[]} // Disables grid lines for the y-axis
                // Add horizontal lines at specified Y-axis values
                layers={[
                    "grid",
                    "axes",
                    "areas",
                    "lines",
                    "points",
                    "slices",
                    "mesh",
                    "legends",
                    CustomLayerWithScoreIntervalExplanation, // Add custom layer here
                ]}
                theme={{
                    axis: {
                        ticks: {
                            text: {
                                fill: textColor, // Text color for axis ticks
                            },
                        },
                        legend: {
                            text: {
                                fill: textColor, // Text color for axis legend
                            },
                        },
                    },
                    legends: {
                        text: {
                            fill: textColor, // Text color for legends
                        },
                    },
                }}
            />
        </div>
    );
}
