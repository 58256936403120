import { PlayingCardDTO } from "./DTO";

export class RequestBody {}

export class UpdatePlayingCardsRequest implements RequestBody {
  roomUrlId: string;
  cardDeckType: CardDeckType = CardDeckType.Scrum;
  playingCards: PlayingCardDTO[] = [];

  constructor(roomUrlId: string) {
    this.roomUrlId = roomUrlId;
  }
}

export class CreateRoomRequest  implements RequestBody {
  playerName: string;
  cardDeckType?: CardDeckType;
  playingCards?: PlayingCardDTO[] = [];

 constructor(playerName: string) {
    this.playerName = playerName;
  }
}

export enum CardDeckType {
  Unknown = "",
  Scrum = 0,
  Fibonacci = 1,
  PowersOfTwo = 2,
  TShirt = 3,
}

export interface RoomResponse {
  id: string,
  urlId: string,
  state: string
  players: [{
    id: string,
    userId: string,
    name: string,
    isModerator: boolean,
    isObserver: boolean,
    storyPoint?: string,
    lastSeenAt?: Date,
    connections: [{
      connectionId: number,
      createdAt: Date,
    }]
  }]
  cardDeckType: CardDeckType,
  playingCards: [{
    order: number,
    value: string,
    }],
}

export interface GameInsightsResponse {
    averagePoints?: number,
    averageCard: string,
    alignmentScoreText: string,
}

export interface RecentRoomsResponse {
  recentRoomVisits: [{
    roomUrlId: string,
    playerNames: string,
    daysSinceLastVisit: number,
    player: {
      id: string,
      userId: string,
      name: string,
      isModerator: true
    }
  }]
}

export class SignUpRequest  implements RequestBody {
  email: string;
  
 constructor(email: string) {
    this.email = email;
  }
}

export class EnrollModeratorPowersRequest  implements RequestBody {
  roomUrlId: string;
  playerId: string
  
 constructor(roomUrlId: string, playerId: string) {
  this.roomUrlId = roomUrlId;
  this.playerId = playerId;
  }
}

// TypeScript interface for AlignmentTrendPoint
export interface AlignmentTrendPoint {
  x: string; // ISO string for the date
  y: number; // Average alignment score for the day
}

// TypeScript interface for GameInsightAlignmentTrendResponse
export interface GameInsightAlignmentTrendResponse {
  aglinmentPoints: AlignmentTrendPoint[]; // Array of alignment trend points
}